<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/hero-tranzalpine-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.45) 15%, rgba(22,22,22,0.25) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/great-rail-journeys-of-new-zealand-stacked.png" alt="Great Rail Journeys of New Zealand" class="campaign-title stacked" width="480" height="300" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only">
                    <small>TranzAlpine Train Journey - Photo: KiwiRail</small>
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <v-layout row wrap align-end>
                        <v-flex xs12 md10 py-0 my-0>
                          <span
                            class="headline font-weight-medium kiwiGreen d-block mb-3"
                          >Discover a collection of our most popular self-guided New Zealand Rail packages</span>
                          <p>Climb aboard one of New Zealand’s Great Rail Journeys. Discover three spectacular rail journeys, each showcasing a unique view of New Zealand’s famous scenery. From sleeping volcanoes to cascading waterfalls, sparkling cities and rural farmsteads, golden beaches and snow-capped mountains, you will enjoy an immersive experience and a deeper connection with Aotearoa New Zealand.</p>
                          <p><a target="_blank" href="https://issuu.com/holidayexperts/docs/rail_nzd_2024_issuu?fr=xKAE9_zU1NQ">Download a copy of our 12-page eBrochure</a> which will highlight details of each New Zealand train journey and a collection of our most popular self-guided holiday packages.</p>
                        </v-flex>
                        <v-flex xs12 md2 py-0 my-0 text-xs-center>
                          <a target="_blank" href="https://issuu.com/holidayexperts/docs/rail_nzd_2024_issuu?fr=xKAE9_zU1NQ"><img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/brochure-mini-rail-2023-AUD.jpg" alt="Great Rail Journeys of New Zealand eBrochure Cover" class="campaign-sticker kirra-early-bird" width="124" height="174" /></a>
                          <small class="kiwiGreen d-block mt-2" style="line-height:1.2;"><a target="_blank" href="https://issuu.com/holidayexperts/docs/rail_nzd_2024_issuu?fr=xKAE9_zU1NQ"><b>View eBrochure</b></a></small>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Tour / Package</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (NZD)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-right>
                            <span class="caption grey--text">Inclusions & Terms</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right>
                            <v-btn large class="card-with-border elevation-0 mx-0 px-2" :color="lightGrey" :href="deal.itinerary" target="_blank"><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [      
        {
          title : 'Self-Guided Packages',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-01.jpg',
              name : '7 Day NZ Rail Experience',
              price : '1699',
              complement : 'per person (twin share)',
              code : 'ANZ247DNRE',
              location : 'Auckland (AKL)',
              valid: '03 Apr 2024 – 31 Mar 2025 (select departures)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/7-Day-NZ-Rail-Experience-NZD.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-03.jpg',
              name : '10 Day Southern Tracks',
              price : '2189',
              complement : 'per person (twin share)',
              code : 'ANZ2410DST',
              location : 'Christchurch (CHC)',
              valid: '01 Oct 2024 – 31 Mar 2025 (select departures)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/10-Day-Southern-Tracks-NZD.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-02.jpg',
              name : '10 Day Northern Explorer',
              price : '2489',
              complement : 'per person (twin share)',
              code : 'ANZ2410DNE',
              location : 'Auckland (AKL)',
              valid: '01 Oct 2024 – 31 Mar 2025 (select departures)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/10-Day-Northern-Explorer-NZD.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-04.jpg',
              name : '12 Day Grand Rail Explorer',
              price : '2869',
              complement : 'per person (twin share)',
              code : 'ANZ2412DGRE',
              location : 'Queenstown (ZQN)',
              valid: '08 Apr – 09 Dec 2024 (select departures)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/12-Day-Grand-Rail-ExplorerNZD.pdf'
            },
            /*
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/10d-luxury-rail-explorer_thumbnail-136x136-1.jpg',
              name : '10 Day NZ Luxury Rail Explorer',
              price : '6599',
              complement : 'per person (twin share)',
              code : 'ANZ2410DLRE',
              location : 'Queenstown (ZQN)',
              valid: 'Sep – Dec 2024 (select departures)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/10-Day-NZ-Luxury-Rail-Explorer-AUD-Flyer.pdf'
            },
            */
          ]
        },
        {
          title : 'Guided Rail Tours',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-05.jpg',
              name : '15 Day Classic New Zealand Highlights',
              price : '8549',
              complement : 'per person (twin share)',
              code : 'Call ANZCRO to Book',
              location : 'Auckland (AKL)',
              valid: 'Mar 2024 – Apr 2025 (select departures)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/15-Day-NZ-Highlights-Classic-Tour-NZD-20240213.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-rail-06.jpg',
              name : '17 Day Signature New Zealand Up Close',
              price : '13999',
              complement : 'per person (twin share)',
              code : 'Call ANZCRO to Book',
              location : 'Auckland (AKL)',
              valid: 'Sep 2024 – May 2025 (select departures)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/17-Day-NZ-Up-Close-Signature-Tour-NZD-20240213.pdf'
            },
          ]
        },
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyCode.innerHTML);
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyLocation.innerHTML);
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  